$(document).ready(function () {
  const $root = $('.js-tab-account-setting');
  const $tabProfile = $root.find('.js-tab-profile');
  const $tabSelectItem = $root.find('.js-tab-select-item');

  $tabProfile.on('click', function () {
    const $this = $(this);
    let text = $this.text();

    $tabSelectItem.html(text);
  });
});